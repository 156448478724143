import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import LineItemHeader from './LineItemHeader';
import LineItemImageViewer from './LineItemImageViewer';
import LineItemColorChanges from './LineItemColorChanges';
import Modal from 'react-modal';
import LineItemSizes from './LineItemSizes';
import LineItemTotalBreakdown from './LineItemTotalBreakdown';
import LineItemPromoInformation from './LineItemPromoInformation';
import LineItemUnitPriceEditor from './LineItemUnitPriceEditor';
import LineItemArtReview from './LineItemArtReview';
import ReceivableFields from './ReceivableFields';
import DragHandle from './DragHandle';
import ActionMenu from './ActionMenu';
import Icon from './Icon';
import IconSvg from './IconSvg';
import { Color } from 'BrandColor';
import classNames from 'classnames';
import Notification from './notification';
import { formatMoney, humanizeWithSpaces, formatPercentV2 } from '../utility';
import { localPatchJSON, extractErrorMessage } from '../fetch-local';

export default class LineItem extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      detailedPricingModalIsOpen: false,
      profitabilityModalIsOpen: false,
    };

    this.closeDetailedPricingModal = this.closeDetailedPricingModal.bind(this);
    this.closeProfitabilityModal = this.closeProfitabilityModal.bind(this);
  }

  closeDetailedPricingModal() {
    this.setState({ detailedPricingModalIsOpen: false });
  }

  closeProfitabilityModal() {
    this.setState({ profitabilityModalIsOpen: false });
  }

  // This was brought over from the LineItemUnitPriceEditor component
  // so it probably needs cleaned up a bit, but it is working.

  setPrice() {
    const { props } = this;

    props.onPricingUpdate({ ...props, isLoading: true });

    localPatchJSON(props.links.setPrice, {
      line_item: {
        unit_price: props.data.unitPrice,
        price_overridden: props.data.isPriceOverridden,
      },
    })
      .then(data => {
        props.onPricingUpdate({ ...data.lineItem, isLoaded: true }, data.order);
        if (props.data.canCheckout !== data.order.canCheckout) {
          window.location.reload();
        }
      })
      .catch(error => {
        extractErrorMessage(error, 'There was an issue').then(error => {
          props.onPricingUpdate({ ...props, error });
        });
      });
  }

  handleClick = () => {
    this.setPrice();
  };

  render() {
    const user = this.props.meta.currentUser;
    const canViewOutOfStock = this.props.meta.currentUser.canViewOutOfStock;
    const { isLoaded } = this.props;

    const outOfStockWarning = this.props.data.outOfStock ? (
      <p>
        <strong className="txt-alert">Warning! </strong>
        {this.props.data.outOfStockWarning}
        <br />
        {canViewOutOfStock && (
          <a href={`/stock_alerts`}>View Out of Stock Items</a>
        )}
      </p>
    ) : null;

    const outOfStockSizesWarning = this.props.data.outOfStockSizesWarning ? (
      <p>
        <strong className="txt-alert">Warning! </strong>
        {this.props.data.outOfStockSizesWarning}
        <br />
        {canViewOutOfStock && (
          <a href={`/stock_alerts`}>View Out of Stock Items</a>
        )}
      </p>
    ) : null;

    const priceAdjustedWarning = this.props.data.priceAdjusted ? (
      <Notification type="alert">
        <p className="mtn">
          <strong>Warning! </strong>
          To meet minimum pricing requirements, the unit price of this item has
          been automatically adjusted from{' '}
          <strong>{formatMoney(this.props.data.quotedPrice)}</strong> to{' '}
          <strong>{formatMoney(this.props.data.unitPrice)}</strong>. If your
          most recent change caused this warning, you can undo that change to
          restore the original price. If you wish to accept the new price, use
          the button below.
        </p>
        <button className="button button--alert" onClick={this.handleClick}>
          Keep Adjusted Price
        </button>
      </Notification>
    ) : null;
    const {
      detailedPricingInformation,
      lineItemCosts,
      totalCost,
      totalPriceWithShippingAndRushFees,
      apparelPurchased,
      stale,
    } = this.props.data;

    const profit = totalPriceWithShippingAndRushFees - totalCost;
    const profitMargin =
      totalPriceWithShippingAndRushFees > 0
        ? formatPercentV2(profit / totalPriceWithShippingAndRushFees)
        : '-';

    const staleNotificationUI = stale && (
      <div className="mbl">
        <Notification small type="alert">
          <strong className="mtm mlm">
            This profitability information is outdated.
          </strong>
          <p className="mlm">
            It's likely that a background job hasn't completed yet. Wait a
            minute and refresh the page to see the most recent data.
          </p>
        </Notification>
      </div>
    );

    const tooltipMessages = {
      apparelCosts: apparelPurchased
        ? 'This is the actual purchase cost the purchasing team entered.'
        : 'This is the base price of the product. If the product color has an alternative base price, we use that price. If the item is a custom item, we assume a 43% margin was added and back that out from this cost.',
      artCosts:
        'This is based on the currently selected art tier. If no art tier is set, we fall-back to the Original Art Tier. If the order is a reorder, the art cost reflects that.',
      birdBankFee:
        'This is only calculated if we collected payment on the Bird Bank.',
      decorationCosts:
        'This is the total cost of all decorations. If the order has embroidery and an actual stitch count was entered, we calculate our cost using that stitch count. Note: This also includes any rush production fees (that are not included in the price) as entered on the order.',
      packagingCosts:
        'If Bag & Tag, this calculates the B&T labor cost. If Individual Ship, we assume our cost is the individual shipping cost our customers pay.',
      creditCardProcessingFee:
        'We assume every line item will have a credit card processing fee (unless we collected payment on a Bird Bank).',
      businessDevelopmentLeaderCommission: `This is pulled from the BDL profile at the time that we recorded the line item costs. This is currently set at ${this.props.data.businessDevelopmentLeaderCommissionRate}.`,
      shippingCosts:
        'This includes any built-in bulk shipping costs, additional shipping fees, and supplier decorated shipping fees.',
    };

    const lineItemCostsUI =
      !this.props.data || !lineItemCosts || lineItemCosts.length === 0 ? (
        <p>No profitability information available.</p>
      ) : (
        <table className="table table--header-new table--clean table--compact">
          <thead>
            <tr>
              <th className="txt-left">Description</th>
              <th className="txt-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            {lineItemCosts &&
              Object.entries(lineItemCosts).map(([key, value]) => (
                <tr key={key} className="txt-small">
                  <td>
                    {humanizeWithSpaces(key)}
                    {tooltipMessages[key] && (
                      <span className="tooltip" title={tooltipMessages[key]}>
                        <IconSvg icon="info" color={Color.Blue} size={14} />
                      </span>
                    )}
                  </td>
                  <td className="txt-right">{formatMoney(value)}</td>
                </tr>
              ))}
            <tr>
              <td colspan="2">
                <hr></hr>
              </td>
            </tr>
            <tr className="bg-pale-gray">
              <td>
                <strong>Total Charged (pre-tax)</strong>
              </td>
              <td className="txt-right">
                <strong>
                  {formatMoney(totalPriceWithShippingAndRushFees)}
                </strong>
              </td>
            </tr>
            <tr className="bg-pale-gray">
              <td>
                <strong>Total Cost</strong>
              </td>
              <td className="txt-right">
                <strong>{formatMoney(totalCost)}</strong>
              </td>
            </tr>
            <tr className="bg-pale-gray">
              <td>
                <strong>Contribution $</strong>
              </td>
              <td className="txt-right">
                <strong>{formatMoney(profit)}</strong>
              </td>
            </tr>
            <tr className="bg-pale-gray">
              <td>
                <strong>Contribution %</strong>
              </td>
              <td className="txt-right">
                <strong>{profitMargin}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      );

    const menuItems = [
      this.props.data.birdBankId && (
        <a
          href={`/bird_banks/${this.props.data.birdBankId}`}
          key="view-bird-bank"
        >
          <Icon type="arrow-circle-right" color="inherit" /> View Bird Bank
        </a>
      ),
      detailedPricingInformation && (
        <button
          key="view-detailed-pricing-information"
          onClick={() => this.setState({ detailedPricingModalIsOpen: true })}
        >
          <Icon type="table" color="inherit" /> View Detailed Pricing
          Information
        </button>
      ),
      lineItemCosts && (
        <button
          key="view-profitability-information"
          onClick={() => this.setState({ profitabilityModalIsOpen: true })}
        >
          <Icon type="table" color="inherit" /> View Profitability Information
        </button>
      ),
    ];

    const lineItemMenu =
      menuItems.length > 0 ? (
        <ActionMenu dropdownRight>{menuItems}</ActionMenu>
      ) : null;

    const viewArtReview = user.canViewAffinity ? (
      <LineItemArtReview {...this.props} />
    ) : null;

    const testAttrs = isLoaded ? { 'data-test-loaded': true } : {};

    return (
      <Draggable
        draggableId={this.props.id}
        index={this.props.index}
        isDragDisabled={!this.props.draggable}
      >
        {provided => (
          <div
            className={classNames({
              'line-item': true,
              'out-of-stock': this.props.data.outOfStock,
            })}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...testAttrs}
          >
            {this.props.draggable && (
              <DragHandle {...provided.dragHandleProps} />
            )}

            <div className="line-item__detail">
              <div className="flex-rows flex-rows--spaced-b flex-gap-x-sm">
                <LineItemHeader {...this.props} />
                {lineItemMenu}
              </div>
              {priceAdjustedWarning}
              <div className="line-item__body">
                <div className="line-item-image-viewer line-item-image-viewer--bordered">
                  <LineItemImageViewer images={this.props.data.images} />
                </div>

                <LineItemColorChanges {...this.props} />

                <LineItemSizes {...this.props} />

                {this.props.meta.currentUser.canViewPricingSection && (
                  <div>
                    <LineItemUnitPriceEditor
                      {...this.props}
                      setPrice={this.setPrice}
                    />

                    <LineItemTotalBreakdown {...this.props} />
                  </div>
                )}

                {viewArtReview}

                {this.props.meta.currentUser.canViewPricingSection && (
                  <LineItemPromoInformation {...this.props} />
                )}

                {detailedPricingInformation && (
                  <Modal
                    isOpen={this.state.detailedPricingModalIsOpen}
                    className="react-modal"
                    overlayClassName="react-modal-overlay"
                    onRequestClose={this.closeDetailedPricingModal}
                    contentLabel="Detailed Pricing Information Modal"
                    closeTimeoutMS={200}
                  >
                    <div className="react-modal-header flex-rows flex-rows--space-b">
                      <h4 className="react-modal-header__title">
                        Detailed Pricing
                      </h4>
                    </div>
                    <div className="react-modal-body react-modal-content">
                      <h2 className="secondary-headline">
                        Detailed Pricing Information
                      </h2>
                      <dl className="dl-grid stack stack--x-small pas">
                        {Object.keys(detailedPricingInformation).map(
                          property => [
                            <dt key={`${property}-dt`}>{property}</dt>,
                            <dd className="mll" key={`${property}-dd`}>
                              {detailedPricingInformation[property]}
                            </dd>,
                          ]
                        )}
                      </dl>
                    </div>
                    <div className="react-modal-footer">
                      <button
                        className="button button--primary"
                        onClick={this.closeDetailedPricingModal}
                      >
                        Close
                      </button>
                    </div>
                  </Modal>
                )}
                {lineItemCosts && (
                  <Modal
                    isOpen={this.state.profitabilityModalIsOpen}
                    className="react-modal"
                    overlayClassName="react-modal-overlay"
                    onRequestClose={this.closeProfitabilityModal}
                    contentLabel="Profitability Modal"
                    closeTimeoutMS={200}
                  >
                    <div className="react-modal-header flex-rows flex-rows--space-b">
                      <h4 className="react-modal-header__title">
                        Profitability
                      </h4>
                    </div>
                    <div className="react-modal-body react-modal-content">
                      <h2 className="secondary-headline">
                        Profitability Information
                      </h2>
                      {staleNotificationUI}
                      {lineItemCostsUI}
                    </div>
                    <div className="react-modal-footer">
                      <button
                        className="button button--primary"
                        onClick={this.closeProfitabilityModal}
                      >
                        Close
                      </button>
                    </div>
                  </Modal>
                )}

                <ReceivableFields {...this.props} />
              </div>
              {outOfStockWarning}
              {outOfStockSizesWarning}
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}
