import React from 'react';

export default function SizeChart(props) {
  const sizes = props.sizeChart.sizes;
  const measurements = props.sizeChart.measurements;
  const noSizeChart = !measurements;

  const sizeChartUI = !noSizeChart && (
    <div>
      <table className="product-table bb-callout">
        <thead>
          <tr>
            <th className="txt-small mtn">(In Inches)</th>
            {measurements.map((measurement, key) => (
              <th key={key}>
                <strong>{measurement.value}</strong>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sizes.map((sizeChartRow, k) => (
            <tr key={k}>
              <td>
                <strong>{sizeChartRow.size}</strong>
              </td>
              {measurements.map((measurement, key) => {
                const matchedMeasurement = sizeChartRow.measurements.find(
                  m => m.name === measurement.key
                );
                return (
                  <td key={key} className="txt-center">
                    {matchedMeasurement ? matchedMeasurement.value : '-'}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <p className="txt-small txt-muted mbl">
        <em>Size information may not be available for all sizes</em>
      </p>
    </div>
  );

  const noSizeChartUI = noSizeChart && (
    <div>
      <p className="txt-small">
        Size information isn't available for this product.
      </p>
    </div>
  );

  return (
    <div>
      <h1 className="bb-item-headline">Size Chart</h1>
      {!noSizeChart && sizeChartUI}
      {noSizeChart && noSizeChartUI}
    </div>
  );
}
